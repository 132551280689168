var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "home" } },
    [
      _c(
        "v-container",
        { staticClass: "text-center" },
        [
          _c(
            "v-row",
            {
              style: _vm.rowStyle,
              attrs: { "align-md": _vm.rowAlign, "justify-md": _vm.rowAlign }
            },
            [
              _vm.appConfig.home.watermark &&
              _vm.appConfig.home.watermark.length > 0
                ? _c("div", {
                    staticClass: "font-weight-black watermark",
                    style: {
                      color: _vm.$vuetify.theme.dark ? "#FFFFFF" : "#000000"
                    },
                    domProps: {
                      innerHTML: _vm._s(_vm.appConfig.home.watermark)
                    }
                  })
                : _vm._e(),
              _vm.sso.authenticated() && _vm.appConfig.home.searchable
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6", xl: "4" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "py-4 px-3 fill-height home-card",
                          attrs: { elevation: "3" }
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "v-avatar",
                                { attrs: { color: "secondary", size: "88" } },
                                [
                                  _c("v-icon", { attrs: { "x-large": "" } }, [
                                    _vm._v("mdi-magnify")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card-title",
                            {
                              staticClass:
                                "justify-center text-h5 text-no-wrap overflow-hidden text-truncate"
                            },
                            [_vm._v("Search for E-Documents ")]
                          ),
                          _c("v-text-field", {
                            attrs: {
                              autofocus: _vm.$vuetify.breakpoint.mdAndUp,
                              "background-color": "background",
                              "aria-label": "Search Home",
                              flat: "",
                              solo: "",
                              "hide-details": "",
                              placeholder: "e.g. My Profile"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prepend-inner",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "error" } },
                                        [_vm._v("mdi-magnify")]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              1741733094
                            ),
                            model: {
                              value: _vm.drawerFilter,
                              callback: function($$v) {
                                _vm.drawerFilter = $$v
                              },
                              expression: "drawerFilter"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.homeLibrary, function(item, i) {
                return _c(
                  "v-col",
                  { key: i, attrs: { cols: "12", md: "6", xl: "4" } },
                  [
                    _c(
                      "v-card",
                      _vm._b(
                        {
                          staticClass: "py-4 px-3 fill-height home-card",
                          attrs: { elevation: "3" }
                        },
                        "v-card",
                        item.target,
                        false
                      ),
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "v-avatar",
                              {
                                attrs: {
                                  color: _vm.getMeta(item).color,
                                  size: "88"
                                }
                              },
                              [
                                _c("v-icon", {
                                  attrs: {
                                    dark: !_vm.getMeta(item).light,
                                    "x-large": ""
                                  },
                                  domProps: {
                                    textContent: _vm._s(item["icon"])
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("v-card-title", {
                          staticClass:
                            "justify-center text-h5 text-no-wrap overflow-hidden text-truncate",
                          domProps: { textContent: _vm._s(item["name"]) }
                        }),
                        _c("v-card-text", {
                          staticClass:
                            "justify-center text-subtitle-1 overflow-auto overflow-hidden",
                          style: _vm.columnStyle,
                          domProps: { textContent: _vm._s(item["description"]) }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }