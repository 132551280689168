import { render, staticRenderFns } from "./home.vue?vue&type=template&id=56b687e3&scoped=true&"
import script from "./home.vue?vue&type=script&lang=js&"
export * from "./home.vue?vue&type=script&lang=js&"
import style0 from "./home.vue?vue&type=style&index=0&id=56b687e3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56b687e3",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAvatar,VCard,VCardText,VCardTitle,VCol,VContainer,VIcon,VRow,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("D:\\asyhub-master\\asyhub-maritime-ui\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('56b687e3')) {
      api.createRecord('56b687e3', component.options)
    } else {
      api.reload('56b687e3', component.options)
    }
    module.hot.accept("./home.vue?vue&type=template&id=56b687e3&scoped=true&", function () {
      api.rerender('56b687e3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/home.vue"
export default component.exports